@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Noto+Sans+JP:wght@400;500;700&display=swap");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: "";
  content: none; }

q:before, q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

main {
  display: block; }

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  appearance: none; }

input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none; }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%;
  font-family: "Noto Sans JP", sans-serif;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%; }

body {
  color: #000000;
  line-height: 1.5;
  letter-spacing: 0.064em; }

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer; }

img {
  max-width: 100%;
  width: 100%;
  line-height: 1;
  vertical-align: bottom; }

input {
  outline: none; }

textarea {
  outline: none;
  resize: none; }

.wrap {
  position: relative;
  overflow: hidden;
  padding-right: 12rem; }
  @media screen and (max-width: 1100px) {
    .wrap {
      padding-right: 9rem; } }
  @media screen and (max-width: 768px) {
    .wrap {
      padding-right: 0;
      padding-bottom: 7.2rem; } }
  .wrap.is-no-contact {
    padding-right: 0; }
    @media screen and (max-width: 768px) {
      .wrap.is-no-contact {
        padding-bottom: 0; } }

/* Components */
.btn {
  position: relative; }

/* ヘッダーのボタン */
.btn-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 32px;
  background-image: linear-gradient(90deg, #c9b776 0, #a3935e 50%, #c9b776 100%);
  background-size: 200% 100%;
  background-position: left center;
  color: #fff;
  transition: all 0.3s; }
  @media screen and (max-width: 1100px) {
    .btn-header {
      padding: 0 12px; } }
  .btn-header:hover {
    background-position: right center; }
  .btn-header__en {
    display: inline-block;
    font-family: "Lato", "Noto Sans JP", sans-serif;
    font-size: 2rem;
    letter-spacing: 0; }
    @media screen and (max-width: 1100px) {
      .btn-header__en {
        font-size: 1.7rem; } }
  .btn-header__jp {
    display: inline-block;
    font-size: 1.4rem;
    letter-spacing: 0.22em;
    margin-left: 12px; }

.btn-cv {
  background-image: linear-gradient(90deg, #c9b776 0, #a3935e 50%, #c9b776 100%);
  background-size: 200% 100%;
  background-position: left center;
  font-size: 2.5rem;
  font-weight: bold;
  color: #fff;
  width: 520px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  box-shadow: 0 3px 10px rbba(#000, 0.35);
  margin: 0 auto;
  transition: all 0.3s; }
  @media screen and (max-width: 768px) {
    .btn-cv {
      width: 80%;
      max-width: 380px;
      font-size: 2rem;
      height: 56px; } }
  .btn-cv:hover {
    background-position: right center; }

.container {
  width: 100%;
  max-width: 1260px;
  padding: 0 30px;
  margin: 0 auto; }
  @media screen and (max-width: 768px) {
    .container {
      padding: 0 16px; } }

.tel-info {
  background-color: #fff;
  padding: 48px 0 40px; }
  @media screen and (max-width: 768px) {
    .tel-info {
      padding: 32px 0 28px; } }
  .tel-info__inner {
    display: flex;
    justify-content: center;
    align-items: center; }
    @media screen and (max-width: 900px) {
      .tel-info__inner {
        flex-direction: column; } }
  .tel-info__text {
    position: relative;
    font-size: 2.2rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    margin-right: 74px;
    height: 48px;
    display: flex;
    align-items: center; }
    @media screen and (max-width: 900px) {
      .tel-info__text {
        height: auto;
        margin-right: 0; } }
    @media screen and (max-width: 768px) {
      .tel-info__text {
        font-size: 1.8rem; } }
    .tel-info__text::after {
      content: "";
      width: 1px;
      height: 48px;
      background-color: #a2a2a2;
      display: block;
      position: absolute;
      right: -36px; }
      @media screen and (max-width: 900px) {
        .tel-info__text::after {
          display: none; } }
  .tel-info__link {
    font-family: "Lato", "Noto Sans JP", sans-serif;
    font-weight: bold;
    font-size: 5rem;
    transition: all 0.3s; }
    @media screen and (max-width: 900px) {
      .tel-info__link {
        font-size: 4.2rem; } }
    @media screen and (max-width: 768px) {
      .tel-info__link {
        font-size: 3.2rem; } }
    .tel-info__link::before {
      content: "";
      width: 32px;
      height: 32px;
      background-image: url("../img/icon_tel.png");
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      display: inline-block;
      margin-right: 10px; }
      @media screen and (max-width: 768px) {
        .tel-info__link::before {
          width: 24px;
          height: 24px;
          margin-right: 6px; } }
    .tel-info__link:hover {
      color: #c1a15d; }

.sp-nav {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  transform: translateX(100%);
  transition: all 0.3s;
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  z-index: 99;
  padding: 65px 30px 30px; }
  .sp-nav__item {
    padding: 12px 0;
    border-bottom: 1px solid #e0e0e0; }
  .sp-nav__link {
    font-size: 2rem;
    font-weight: bold;
    font-family: "Lato", "Noto Sans JP", sans-serif;
    color: #9d2c30; }

.header {
  background-color: #fff; }
  @media screen and (max-width: 900px) {
    .header {
      position: relative;
      display: flex;
      padding: 0 30px;
      z-index: 100; } }
  @media screen and (max-width: 768px) {
    .header {
      position: fixed;
      top: 0;
      left: 0;
      height: 55px;
      width: 100%;
      padding: 0 16px;
      background-color: #fff;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1); } }
  .header__top {
    padding: 14px 0 20px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #e0e0e0; }
    @media screen and (max-width: 900px) {
      .header__top {
        border-bottom: none; } }
    @media screen and (max-width: 768px) {
      .header__top {
        padding: 5px 0 6px; } }
  .header__logo {
    display: block;
    width: 103px;
    transition: all 0.3s; }
    @media screen and (max-width: 900px) {
      .header__logo {
        width: 60px; } }
    @media screen and (max-width: 768px) {
      .header__logo {
        width: 50px; } }
  .header a.header__logo:hover {
    opacity: 0.7; }
  .header__nav {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 900px) {
      .header__nav {
        display: none; } }
  .header__nav-container {
    display: flex;
    justify-content: space-between;
    padding-right: 0; }
  .header__nav-list {
    display: flex;
    height: 56px; }
  .header__nav-item {
    margin-right: 44px;
    height: 100%; }
    @media screen and (max-width: 1100px) {
      .header__nav-item {
        margin-right: 22px; } }
    .header__nav-item:last-child {
      margin-right: 0; }
  .header__nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-family: "Lato", "Noto Sans JP", sans-serif;
    font-size: 1.7rem;
    font-weight: bold;
    letter-spacing: 0.22em;
    transition: all 0.3s; }
    @media screen and (max-width: 1100px) {
      .header__nav-link {
        letter-spacing: 0.1em; } }
    .header__nav-link:hover {
      color: #9d2c30; }
  .header__hamburger {
    position: absolute;
    right: 30px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 40px;
    height: 40px;
    display: none; }
    @media screen and (max-width: 900px) {
      .header__hamburger {
        display: block; } }
    @media screen and (max-width: 768px) {
      .header__hamburger {
        right: 16px;
        width: 30px;
        height: 30px; } }
    .header__hamburger::before, .header__hamburger::after {
      content: ""; }
    .header__hamburger::before, .header__hamburger::after,
    .header__hamburger span {
      position: absolute;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 2px;
      background-color: #9d2c30;
      transition: all 0.3s; }
    .header__hamburger::before {
      top: 4px; }
    .header__hamburger::after {
      bottom: 4px; }
    .header__hamburger span {
      top: 0;
      bottom: 0;
      margin: auto 0; }

.main {
  display: block; }
  @media screen and (max-width: 768px) {
    .main {
      padding-top: 55px; } }

.footer__main {
  background-color: #353030;
  padding: 72px 0 86px; }
  @media screen and (max-width: 768px) {
    .footer__main {
      padding: 50px 0 60px; } }

.footer__container {
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .footer__container {
      flex-wrap: wrap; } }

.footer__info {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start; }
  @media screen and (max-width: 768px) {
    .footer__info {
      width: 100%;
      justify-content: center; } }

.footer__logo {
  width: 142px;
  margin-right: 34px;
  margin-bottom: 20px; }
  @media screen and (max-width: 768px) {
    .footer__logo {
      width: 80px;
      margin-right: 0;
      margin-bottom: 0; } }

.footer__tel {
  font-family: "Lato", "Noto Sans JP", sans-serif;
  font-size: 4.2rem;
  font-weight: bold;
  color: #fff;
  line-height: 1;
  transition: all 0.3s; }
  @media screen and (max-width: 1100px) {
    .footer__tel {
      font-size: 3.2rem; } }
  @media screen and (max-width: 768px) {
    .footer__tel {
      width: 100%;
      text-align: center;
      margin-top: 20px; } }
  .footer__tel::before {
    content: "";
    width: 32px;
    height: 32px;
    background-image: url("../img/icon_tel.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    display: inline-block;
    margin-right: 6px; }
    @media screen and (max-width: 1100px) {
      .footer__tel::before {
        width: 24px;
        height: 24px; } }
  .footer__tel:hover {
    color: #c1a15d; }

@media screen and (max-width: 768px) {
  .footer__nav {
    margin-top: 30px; } }

.footer__nav-cols {
  display: flex; }
  @media screen and (max-width: 768px) {
    .footer__nav-cols {
      flex-wrap: wrap; } }

.footer__nav-col {
  padding: 0 42px;
  white-space: nowrap; }
  @media screen and (max-width: 900px) {
    .footer__nav-col {
      padding: 0 24px; } }
  @media screen and (max-width: 768px) {
    .footer__nav-col {
      width: 100%;
      padding: 0; } }
  .footer__nav-col:nth-child(1) {
    border-right: 1px solid #706d6d; }
    @media screen and (max-width: 900px) {
      .footer__nav-col:nth-child(1) {
        padding-left: 0; } }
    @media screen and (max-width: 768px) {
      .footer__nav-col:nth-child(1) {
        border-right: 0; } }
  .footer__nav-col:nth-child(3) {
    padding-right: 0;
    border-left: 1px solid #706d6d; }
    @media screen and (max-width: 768px) {
      .footer__nav-col:nth-child(3) {
        border-left: 0; } }
  @media screen and (max-width: 768px) {
    .footer__nav-col:not(:first-child) {
      margin-top: 30px; } }

.footer__nav-col-head {
  color: #fff;
  font-family: "Lato", "Noto Sans JP", sans-serif;
  font-size: 2.3rem;
  font-weight: bold; }
  @media screen and (max-width: 768px) {
    .footer__nav-col-head {
      font-size: 2rem; } }

.footer__nav-list {
  margin-top: 14px; }
  @media screen and (max-width: 768px) {
    .footer__nav-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 8px; } }

.footer__nav-item {
  color: #aeaeae;
  font-size: 1.5rem;
  letter-spacing: 0.1em; }
  @media screen and (max-width: 768px) {
    .footer__nav-item {
      margin-right: 16px; } }

.footer__nav-link {
  display: inline-block;
  line-height: 2.4;
  transition: all 0.3s; }
  @media screen and (max-width: 768px) {
    .footer__nav-link {
      line-height: 2; } }
  .footer__nav-link:hover {
    opacity: 0.7; }

.footer__copy {
  background-color: #9d2c30;
  background-image: linear-gradient(90deg, #8a1d21 0, #9d2c30 50%, #8a1d21 100%);
  color: #aeaeae;
  font-size: 1.6rem;
  padding: 20px 0; }

.footer__copy-container {
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .footer__copy-container {
      flex-wrap: wrap; } }

.footer__copy-list {
  display: flex; }
  @media screen and (max-width: 768px) {
    .footer__copy-list {
      width: 100%;
      justify-content: center; } }

.footer__copy-item {
  margin-right: 6px; }
  @media screen and (max-width: 768px) {
    .footer__copy-item {
      margin: 0 6px; } }

.footer__copy-link {
  transition: all 0.3s; }
  .footer__copy-link:hover {
    opacity: 0.7;
    text-decoration: underline; }

@media screen and (max-width: 768px) {
  .footer__copy-text {
    width: 100%;
    text-align: center;
    font-size: 1.3rem;
    margin-top: 8px; } }

.cv {
  background-image: url("../img/cv_bg.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 148px 0 140px; }
  @media screen and (max-width: 900px) {
    .cv {
      padding: 128px 0 120px; } }
  @media screen and (max-width: 768px) {
    .cv {
      padding: 60px 0; } }
  .cv__container {
    position: relative; }
  .cv__title {
    font-size: 4.5rem;
    font-weight: bold;
    color: #fff;
    text-align: center;
    line-height: 1.44; }
    @media screen and (max-width: 900px) {
      .cv__title {
        font-size: 3.6rem;
        line-height: 1.5; } }
    @media screen and (max-width: 768px) {
      .cv__title {
        font-size: 2.8rem;
        line-height: 1.75; } }
  .cv__btn {
    margin-top: 56px; }
    @media screen and (max-width: 768px) {
      .cv__btn {
        margin-top: 30px; } }
  .cv__link-text {
    color: #fff;
    text-align: center;
    text-decoration: underline;
    color: #fff;
    font-size: 1.6rem;
    letter-spacing: 0.1em;
    margin-top: 24px; }
    .cv__link-text a {
      transition: all 0.3s; }
      .cv__link-text a:hover {
        opacity: 0.7; }

.page-top .mv {
  position: relative;
  width: 100%; }
  .page-top .mv__title {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
    font-weight: bold;
    color: #fff;
    text-align: center;
    letter-spacing: 0.04em;
    line-height: 1.6; }
    @media screen and (max-width: 1100px) {
      .page-top .mv__title {
        font-size: 3.6vw; } }
    @media screen and (max-width: 768px) {
      .page-top .mv__title {
        font-size: 4.6vw;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.4); } }
  .page-top .mv__bg {
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto; }
  .page-top .mv__img {
    width: 46%;
    position: absolute;
    right: 5%;
    top: 6%; }

.page-top .news {
  border-top: 8px solid #9d2c30;
  padding: 108px 0 134px; }
  @media screen and (max-width: 768px) {
    .page-top .news {
      padding: 60px 0; } }
  .page-top .news__title-en {
    font-family: "Lato", "Noto Sans JP", sans-serif;
    font-size: 4.5rem;
    font-weight: bold;
    letter-spacing: 0.04em; }
    @media screen and (max-width: 768px) {
      .page-top .news__title-en {
        font-size: 3.2rem; } }
  .page-top .news__title-jp {
    color: #9d2c30;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0.22em; }
  .page-top .news__list {
    border-top: 1px solid #767676;
    margin-top: 16px;
    padding: 0 48px; }
    @media screen and (max-width: 900px) {
      .page-top .news__list {
        padding: 0; } }
  .page-top .news__item {
    border-bottom: 1px solid #cdcdcd; }
  .page-top .news__item-head {
    position: relative;
    padding: 44px 0 40px;
    display: flex;
    align-items: center;
    cursor: pointer; }
    @media screen and (max-width: 900px) {
      .page-top .news__item-head {
        padding: 32px 0 30px; } }
    @media screen and (max-width: 768px) {
      .page-top .news__item-head {
        flex-wrap: wrap;
        padding: 20px 0; } }
    .page-top .news__item-head::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto 0;
      background-image: url("../img/icon_arrow.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 20px;
      height: 12px;
      transition: all 0.3s; }
  .page-top .news__item-time {
    color: #a2a2a2;
    font-size: 1.6rem;
    letter-spacing: 0.1em; }
  .page-top .news__item-cat {
    display: inline-block;
    width: 100px;
    height: 25px;
    line-height: 25px;
    background-color: #b2a095;
    color: #fff;
    font-size: 1.4rem;
    font-weight: 500;
    text-align: center;
    margin-left: 22px;
    flex-shrink: 0; }
  .page-top .news__item-title {
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0.1em;
    margin-left: 32px;
    padding-right: 32px; }
    @media screen and (max-width: 768px) {
      .page-top .news__item-title {
        width: 100%;
        margin-left: 0;
        margin-top: 12px; } }
  .page-top .news__item-content {
    display: none;
    cursor: pointer;
    padding: 0 0 44px; }
    @media screen and (max-width: 768px) {
      .page-top .news__item-content {
        flex-wrap: wrap;
        padding: 0 0 20px; } }
    .page-top .news__item-content.is-open {
      display: block; }
    .page-top .news__item-content p {
      font-size: 1.6rem;
      line-height: 2;
      letter-spacing: 0.1em; }
      @media screen and (max-width: 768px) {
        .page-top .news__item-content p {
          font-size: 1.5rem;
          line-height: 1.75; } }
  .page-top .news__item.is-active .news__item-head::after {
    transform: rotate(180deg); }

.page-top .concept {
  background-color: #9d2c30;
  position: relative;
  overflow: hidden; }
  @media screen and (max-width: 900px) {
    .page-top .concept__container {
      display: flex;
      flex-wrap: wrap;
      padding: 0; } }
  .page-top .concept__content {
    padding: 104px 0 124px calc(50% + 54px);
    color: #fff; }
    @media screen and (max-width: 1100px) {
      .page-top .concept__content {
        padding: 64px 0 64px calc(50% + 32px); } }
    @media screen and (max-width: 900px) {
      .page-top .concept__content {
        width: 100%;
        padding: 40px 30px 50px; } }
    @media screen and (max-width: 768px) {
      .page-top .concept__content {
        padding: 40px 16px 40px; } }
  .page-top .concept__blur {
    width: 1100px;
    position: absolute;
    left: 4%;
    top: -90%; }
  .page-top .concept__title {
    position: relative;
    padding-bottom: 20px; }
    .page-top .concept__title::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: #fff;
      height: 3px;
      width: 100px; }
  .page-top .concept__title-en {
    font-family: "Lato", "Noto Sans JP", sans-serif;
    font-size: 4.5rem;
    font-weight: bold;
    letter-spacing: 0.04em; }
    @media screen and (max-width: 768px) {
      .page-top .concept__title-en {
        font-size: 3.2rem; } }
  .page-top .concept__title-jp {
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0.22em; }
  .page-top .concept__text {
    position: relative;
    font-size: 1.6rem;
    line-height: 2;
    letter-spacing: 0.1em;
    margin-top: 24px; }
    @media screen and (max-width: 768px) {
      .page-top .concept__text {
        font-size: 1.5rem;
        line-height: 1.75; } }
  .page-top .concept__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    z-index: 1; }
    @media screen and (max-width: 900px) {
      .page-top .concept__img {
        position: relative;
        left: auto;
        top: auto;
        width: 100%; } }
    .page-top .concept__img img {
      height: 100%;
      width: 100%;
      object-fit: cover; }

.page-top .mission {
  padding: 122px 0 124px;
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    .page-top .mission {
      padding: 60px 0; } }
  .page-top .mission__container {
    position: relative; }
  .page-top .mission__img {
    position: absolute;
    width: 720px;
    top: -46%;
    right: -8%; }
    @media screen and (max-width: 900px) {
      .page-top .mission__img {
        width: 60%;
        top: -32%;
        right: -5%; } }
    @media screen and (max-width: 768px) {
      .page-top .mission__img {
        width: 280px;
        top: -120px;
        right: 0; } }
  .page-top .mission__title {
    padding-bottom: 32px;
    position: relative; }
    @media screen and (max-width: 768px) {
      .page-top .mission__title {
        padding-bottom: 20px; } }
    .page-top .mission__title::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100px;
      height: 3px;
      background-color: #9d2c30; }
  .page-top .mission__title-en {
    font-family: "Lato", "Noto Sans JP", sans-serif;
    font-size: 4.5rem;
    font-weight: bold;
    letter-spacing: 0.04em; }
    @media screen and (max-width: 768px) {
      .page-top .mission__title-en {
        font-size: 3.2rem; } }
  .page-top .mission__title-jp {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0.22em;
    color: #9d2c30;
    margin-left: 8px; }
    .page-top .mission__title-jp.font-lato {
      font-family: "Lato", "Noto Sans JP", sans-serif;
      font-size: 1.8rem;
      letter-spacing: 0.04em; }
  .page-top .mission__sub-title {
    font-size: 3rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    line-height: 1.63;
    margin-top: 22px; }
    @media screen and (max-width: 768px) {
      .page-top .mission__sub-title {
        font-size: 2rem; } }
  .page-top .mission__text {
    font-size: 1.6rem;
    line-height: 1.875;
    letter-spacing: 0.1em;
    margin-top: 58px; }
    @media screen and (max-width: 768px) {
      .page-top .mission__text {
        margin-top: 24px; } }
  .page-top .mission__cards {
    display: flex;
    justify-content: space-between;
    margin-top: 80px; }
    @media screen and (max-width: 768px) {
      .page-top .mission__cards {
        flex-wrap: wrap;
        margin-top: 40px; } }
  .page-top .mission__card {
    width: 47.5%; }
    @media screen and (max-width: 768px) {
      .page-top .mission__card {
        width: 100%; }
        .page-top .mission__card:not(:first-child) {
          margin-top: 30px; } }
  .page-top .mission__card-link {
    position: relative;
    display: block;
    height: 220px;
    overflow: hidden;
    box-shadow: 0 3px 24px rgba(0, 0, 0, 0.24); }
    @media screen and (max-width: 768px) {
      .page-top .mission__card-link {
        height: 180px; } }
    .page-top .mission__card-link::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(6, 165, 99, 0.7);
      pointer-events: none; }
  .page-top .mission__card-link img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.8s;
    transform-origin: center center; }
  .page-top .mission__card-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 3rem;
    letter-spacing: 0.04em;
    font-weight: bold;
    z-index: 1; }
    @media screen and (max-width: 768px) {
      .page-top .mission__card-text {
        font-size: 2.4rem; } }
  .page-top .mission__card-link:hover img {
    transform: scale(1.1); }

.page-top .solution {
  background-color: #f6f1eb;
  padding: 104px 0 142px; }
  @media screen and (max-width: 768px) {
    .page-top .solution {
      padding: 60px 0; } }
  .page-top .solution__title {
    position: relative;
    font-size: 4.5rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    text-align: center;
    padding-bottom: 28px; }
    @media screen and (max-width: 768px) {
      .page-top .solution__title {
        font-size: 2.8rem; } }
    .page-top .solution__title::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      height: 3px;
      background-color: #9d2c30;
      margin: 0 auto; }
  .page-top .solution__intro {
    font-size: 2.4rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    text-align: center;
    margin-top: 22px; }
    @media screen and (max-width: 768px) {
      .page-top .solution__intro {
        font-size: 2rem; } }
  .page-top .solution__list {
    margin-top: 100px; }
    @media screen and (max-width: 768px) {
      .page-top .solution__list {
        margin-top: 40px; } }
  .page-top .solution__item {
    display: flex; }
    @media screen and (max-width: 900px) {
      .page-top .solution__item {
        flex-wrap: wrap;
        max-width: 680px;
        margin: 0 auto; } }
    .page-top .solution__item.is-reverse {
      flex-direction: row-reverse; }
    .page-top .solution__item:not(:first-child) {
      margin-top: 70px; }
      @media screen and (max-width: 768px) {
        .page-top .solution__item:not(:first-child) {
          margin-top: 30px; } }
  .page-top .solution__item-img {
    width: 50%; }
    @media screen and (max-width: 900px) {
      .page-top .solution__item-img {
        width: 100%;
        height: 300px; } }
    @media screen and (max-width: 768px) {
      .page-top .solution__item-img {
        height: 240px; } }
    .page-top .solution__item-img img {
      height: 100%;
      object-fit: cover; }
  .page-top .solution__item-content {
    width: 50%;
    background-image: linear-gradient(90deg, #f5edd9, #ded5b8);
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media screen and (max-width: 900px) {
      .page-top .solution__item-content {
        width: 100%; } }
    @media screen and (max-width: 768px) {
      .page-top .solution__item-content {
        padding: 16px; } }
  .page-top .solution__item-title {
    position: relative;
    color: #b7a062;
    font-size: 3rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    padding-bottom: 6px;
    background-image: repeating-linear-gradient(90deg, #fff 0, #fff 3px, #8e8e8e 3px, #8e8e8e 7px);
    background-repeat: repeat-x;
    background-position: left bottom;
    background-size: 100% 1px; }
    @media screen and (max-width: 768px) {
      .page-top .solution__item-title {
        font-size: 2.4rem;
        padding-bottom: 14px; } }
  .page-top .solution__item-text {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.875;
    letter-spacing: 0.1em;
    margin-top: 16px; }
    @media screen and (max-width: 768px) {
      .page-top .solution__item-text {
        font-size: 1.5rem; } }
  .page-top .solution__box {
    position: relative;
    background-color: #f7f3e8;
    border: 3px solid #b7a062;
    padding: 24px 30px;
    margin-top: 30px;
    min-height: 184px; }
    @media screen and (max-width: 768px) {
      .page-top .solution__box {
        padding: 16px 12px;
        margin-top: 20px;
        min-height: 0; } }
    .page-top .solution__box::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      border: 1px solid #b7a062;
      pointer-events: none; }
      @media screen and (max-width: 768px) {
        .page-top .solution__box::before {
          width: calc(100% - 6px);
          height: calc(100% - 6px); } }
  .page-top .solution__box-head {
    font-size: 2.2rem;
    font-weight: bold;
    letter-spacing: 0.04em; }
    @media screen and (max-width: 768px) {
      .page-top .solution__box-head {
        font-size: 1.8rem; } }
  .page-top .solution__box-en {
    position: absolute;
    top: 8px;
    right: 30px;
    color: #b7a062;
    opacity: 0.2;
    font-family: "Lato", "Noto Sans JP", sans-serif;
    font-size: 4.5rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    line-height: 1; }
    @media screen and (max-width: 768px) {
      .page-top .solution__box-en {
        font-size: 3.2rem;
        right: 12px; } }
  .page-top .solution__box-list {
    margin-top: 16px; }
    @media screen and (max-width: 768px) {
      .page-top .solution__box-list {
        margin-top: 8px; } }
  .page-top .solution__box-item {
    display: flex;
    font-size: 1.6rem;
    letter-spacing: 0.03em;
    line-height: 1.875; }
    @media screen and (max-width: 768px) {
      .page-top .solution__box-item {
        font-size: 1.5rem; } }
    .page-top .solution__box-item::before {
      content: "●"; }

.page-sub .sub-mv {
  height: 306px;
  font-size: 4rem;
  font-weight: bold;
  letter-spacing: 0.04em;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 6px solid #9d2c30;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  @media screen and (max-width: 768px) {
    .page-sub .sub-mv {
      font-size: 3.2rem;
      height: 200px; } }

.page-company .mv {
  background-image: url("../img/company/mv_bg.jpg"); }

.page-company .overview {
  background-color: #f6f1eb;
  padding: 80px 0 96px; }
  @media screen and (max-width: 768px) {
    .page-company .overview {
      padding: 60px 0; } }
  .page-company .overview__title-en {
    font-family: "Lato", "Noto Sans JP", sans-serif;
    font-size: 4.5rem;
    font-weight: bold;
    letter-spacing: 0.04em; }
    @media screen and (max-width: 768px) {
      .page-company .overview__title-en {
        font-size: 3.2rem; } }
  .page-company .overview__title-jp {
    color: #9d2c30;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0.22em; }
  .page-company .overview__list {
    border-top: 1px solid #767676;
    margin-top: 16px;
    padding: 0 48px; }
    @media screen and (max-width: 768px) {
      .page-company .overview__list {
        padding: 0; } }
  .page-company .overview__row {
    display: flex;
    border-bottom: 1px solid #cdcdcd;
    padding: 26px 0; }
    @media screen and (max-width: 768px) {
      .page-company .overview__row {
        padding: 16px 0; } }
  .page-company .overview__dt {
    font-size: 1.8rem;
    letter-spacing: 0.1em;
    min-width: 146px; }
    @media screen and (max-width: 768px) {
      .page-company .overview__dt {
        font-size: 1.6rem;
        min-width: 100px; } }
  .page-company .overview__dd {
    font-size: 18px;
    letter-spacing: 0.1em; }
    @media screen and (max-width: 768px) {
      .page-company .overview__dd {
        font-size: 1.6rem; } }

.page-qrservice .mv {
  background-image: url("../img/qrservice/mv_bg.jpg"); }
  @media screen and (max-width: 768px) {
    .page-qrservice .mv {
      background-position: left center; } }

.page-qrservice .qr {
  position: relative;
  padding: 110px 0; }
  @media screen and (max-width: 768px) {
    .page-qrservice .qr {
      padding: 60px 0; } }
  .page-qrservice .qr::before {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 1176px;
    height: 382px;
    background-image: url("../img/qrservice/qr_bg.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }
  .page-qrservice .qr__title {
    padding-bottom: 32px;
    position: relative; }
    @media screen and (max-width: 768px) {
      .page-qrservice .qr__title {
        padding-bottom: 20px; } }
    .page-qrservice .qr__title::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100px;
      height: 3px;
      background-color: #9d2c30; }
  .page-qrservice .qr__title-en {
    font-family: "Lato", "Noto Sans JP", sans-serif;
    font-size: 4.5rem;
    font-weight: bold;
    letter-spacing: 0.04em; }
    @media screen and (max-width: 768px) {
      .page-qrservice .qr__title-en {
        font-size: 3.2rem; } }
  .page-qrservice .qr__title-jp {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0.22em;
    color: #9d2c30;
    margin-left: 8px; }
  .page-qrservice .qr__sub-title {
    font-size: 3rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    line-height: 1.63;
    margin-top: 22px; }
    @media screen and (max-width: 768px) {
      .page-qrservice .qr__sub-title {
        font-size: 2rem; } }
  .page-qrservice .qr__text {
    font-size: 1.6rem;
    line-height: 1.875;
    letter-spacing: 0.1em;
    margin-top: 30px; }
    @media screen and (max-width: 768px) {
      .page-qrservice .qr__text {
        margin-top: 24px; } }

.page-qrservice .qr-point {
  background-color: #9d2c30;
  padding: 90px 0 100px; }
  @media screen and (max-width: 768px) {
    .page-qrservice .qr-point {
      padding: 60px 0; } }
  .page-qrservice .qr-point__title {
    position: relative;
    font-size: 4.5rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    text-align: center;
    color: #fff;
    padding-bottom: 28px; }
    @media screen and (max-width: 768px) {
      .page-qrservice .qr-point__title {
        font-size: 2.8rem; } }
    .page-qrservice .qr-point__title::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      height: 3px;
      background-color: #fff;
      margin: 0 auto; }
  .page-qrservice .qr-point__list {
    display: flex;
    justify-content: space-between;
    margin-top: 44px; }
    @media screen and (max-width: 768px) {
      .page-qrservice .qr-point__list {
        flex-wrap: wrap;
        max-width: 420px;
        margin: 44px auto 0; } }
  .page-qrservice .qr-point__item {
    width: 31.25%; }
    @media screen and (max-width: 768px) {
      .page-qrservice .qr-point__item {
        width: 100%; }
        .page-qrservice .qr-point__item:not(:first-child) {
          margin-top: 30px; } }
    .page-qrservice .qr-point__item .qr-card__content {
      padding-bottom: 56px; }
      @media screen and (max-width: 1100px) {
        .page-qrservice .qr-point__item .qr-card__content {
          padding-bottom: 32px; } }
      @media screen and (max-width: 768px) {
        .page-qrservice .qr-point__item .qr-card__content {
          padding-bottom: 16px; } }

.page-qrservice .flow {
  background-color: #f6f1eb;
  padding: 100px 0 132px; }
  @media screen and (max-width: 768px) {
    .page-qrservice .flow {
      padding: 60px 0; } }
  .page-qrservice .flow__title {
    position: relative;
    font-size: 4.5rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    text-align: center;
    padding-bottom: 28px; }
    @media screen and (max-width: 768px) {
      .page-qrservice .flow__title {
        font-size: 2.8rem; } }
    .page-qrservice .flow__title::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      height: 3px;
      background-color: #9d2c30;
      margin: 0 auto; }
  .page-qrservice .flow__list {
    margin-top: 86px; }
    @media screen and (max-width: 768px) {
      .page-qrservice .flow__list {
        margin-top: 44px; } }
  .page-qrservice .flow__item {
    display: flex; }
    .page-qrservice .flow__item:not(:first-child) {
      margin-top: 44px; }
      @media screen and (max-width: 768px) {
        .page-qrservice .flow__item:not(:first-child) {
          margin-top: 30px; } }
    @media screen and (max-width: 768px) {
      .page-qrservice .flow__item {
        flex-wrap: wrap; } }
  .page-qrservice .flow__img {
    width: 50%; }
    @media screen and (max-width: 768px) {
      .page-qrservice .flow__img {
        width: 100%;
        height: 240px; } }
    .page-qrservice .flow__img img {
      object-fit: cover;
      height: 100%;
      width: 100%; }
  .page-qrservice .flow__content {
    width: 50%;
    background-image: linear-gradient(90deg, #f5edd9, #ded5b8);
    padding: 54px 32px; }
    @media screen and (max-width: 768px) {
      .page-qrservice .flow__content {
        width: 100%;
        padding: 16px; } }
  .page-qrservice .flow__item-title {
    position: relative;
    color: #b7a062;
    font-size: 3rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    padding-bottom: 6px;
    background-image: repeating-linear-gradient(90deg, #fff 0, #fff 3px, #8e8e8e 3px, #8e8e8e 7px);
    background-repeat: repeat-x;
    background-position: left bottom;
    background-size: 100% 1px; }
    @media screen and (max-width: 768px) {
      .page-qrservice .flow__item-title {
        font-size: 2.4rem;
        padding-bottom: 14px; } }
  .page-qrservice .flow__item-text {
    font-size: 1.6rem;
    line-height: 1.875;
    letter-spacing: 0.1em;
    margin-top: 14px; }
    @media screen and (max-width: 768px) {
      .page-qrservice .flow__item-text {
        font-size: 1.5rem; } }

.page-qrservice .factory {
  padding: 92px 0 98px; }
  @media screen and (max-width: 768px) {
    .page-qrservice .factory {
      padding: 60px 0; } }
  .page-qrservice .factory__title {
    position: relative;
    font-size: 4.5rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    text-align: center;
    padding-bottom: 28px; }
    @media screen and (max-width: 768px) {
      .page-qrservice .factory__title {
        font-size: 2.8rem; } }
    .page-qrservice .factory__title::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      height: 3px;
      background-color: #9d2c30;
      margin: 0 auto; }
  .page-qrservice .factory__img {
    margin-top: 52px; }
  .page-qrservice .factory__text {
    font-size: 1.6rem;
    line-height: 1.875;
    letter-spacing: 0.1em;
    margin-top: 24px; }
    @media screen and (max-width: 768px) {
      .page-qrservice .factory__text {
        font-size: 1.5rem; } }

.page-qrservice .media {
  position: relative;
  border-top: 1px solid #bfbfbf;
  padding: 104px 0 120px; }
  @media screen and (max-width: 768px) {
    .page-qrservice .media {
      padding: 60px 0; } }
  .page-qrservice .media::before {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 962px;
    height: 423px;
    background-image: url("../img/qrservice/media_bg.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }
    @media screen and (max-width: 768px) {
      .page-qrservice .media::before {
        width: 481px;
        height: 211px;
        background-size: contain;
        background-position: right top; } }
  .page-qrservice .media__title {
    padding-bottom: 32px;
    position: relative; }
    .page-qrservice .media__title::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100px;
      height: 3px;
      background-color: #9d2c30; }
  .page-qrservice .media__title-en {
    font-family: "Lato", "Noto Sans JP", sans-serif;
    font-size: 4.5rem;
    font-weight: bold;
    letter-spacing: 0.04em; }
    @media screen and (max-width: 768px) {
      .page-qrservice .media__title-en {
        font-size: 3.2rem; } }
  .page-qrservice .media__title-jp {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0.22em;
    color: #9d2c30;
    margin-left: 8px; }
  .page-qrservice .media__sub-title {
    font-size: 3rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    line-height: 1.63;
    margin-top: 22px; }
    @media screen and (max-width: 768px) {
      .page-qrservice .media__sub-title {
        font-size: 2rem; } }
  .page-qrservice .media__text {
    font-size: 1.6rem;
    line-height: 1.875;
    letter-spacing: 0.1em;
    margin-top: 30px; }
    @media screen and (max-width: 768px) {
      .page-qrservice .media__text {
        margin-top: 24px; } }

.page-qrservice .media-point {
  background-color: #9d2c30;
  padding: 90px 0 100px; }
  @media screen and (max-width: 768px) {
    .page-qrservice .media-point {
      padding: 60px 0; } }
  .page-qrservice .media-point__title {
    position: relative;
    font-size: 4.5rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    text-align: center;
    color: #fff;
    padding-bottom: 28px; }
    @media screen and (max-width: 768px) {
      .page-qrservice .media-point__title {
        font-size: 3.2rem; } }
    .page-qrservice .media-point__title::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      height: 3px;
      background-color: #fff;
      margin: 0 auto; }
  .page-qrservice .media-point__list {
    display: flex;
    justify-content: space-between;
    margin-top: 44px; }
    @media screen and (max-width: 768px) {
      .page-qrservice .media-point__list {
        flex-wrap: wrap;
        max-width: 420px;
        margin: 44px auto 0; } }
  .page-qrservice .media-point__item {
    width: 31.25%; }
    @media screen and (max-width: 768px) {
      .page-qrservice .media-point__item {
        width: 100%; }
        .page-qrservice .media-point__item:not(:first-child) {
          margin-top: 30px; } }
    .page-qrservice .media-point__item .qr-card__title {
      min-height: 82px; }
      @media screen and (max-width: 768px) {
        .page-qrservice .media-point__item .qr-card__title {
          min-height: 0; } }

.page-qrservice .qr-card {
  display: flex;
  flex-direction: column; }
  .page-qrservice .qr-card__img {
    width: 100%; }
  .page-qrservice .qr-card__content {
    background-color: #fff;
    padding: 28px 30px;
    flex-grow: 1; }
    @media screen and (max-width: 1100px) {
      .page-qrservice .qr-card__content {
        padding: 28px 20px; } }
    @media screen and (max-width: 768px) {
      .page-qrservice .qr-card__content {
        padding: 16px; } }
  .page-qrservice .qr-card__title {
    line-height: 1.346;
    color: #6fb07d;
    font-size: 2.6rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    padding-bottom: 14px;
    background-image: repeating-linear-gradient(90deg, #8e8e8e 0, #8e8e8e 5px, #fff 5px, #fff 7px);
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 100% 1px; }
    @media screen and (max-width: 768px) {
      .page-qrservice .qr-card__title {
        font-size: 2.4rem; } }
  .page-qrservice .qr-card__text {
    font-size: 16px;
    line-height: 1.875;
    margin-top: 10px; }

.js-inview {
  transition: 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transform: translateY(3rem);
  opacity: 0; }
  .js-inview.js-inview-active {
    transform: translateY(0);
    opacity: 1; }

/* ハンバーガーメニューONの時 */
@media screen and (max-width: 900px) {
  body.sp-nav-open .header__hamburger span {
    transform: translateX(20px);
    opacity: 0; }
  body.sp-nav-open .header__hamburger::before {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: rotate(45deg);
    transform-origin: center center;
    margin: auto; }
  body.sp-nav-open .header__hamburger::after {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: rotate(-45deg);
    transform-origin: center center;
    margin: auto; }
  body.sp-nav-open .sp-nav {
    transform: translateX(0);
    opacity: 1;
    visibility: visible; } }

.sp {
  display: none; }
  @media screen and (max-width: 768px) {
    .sp {
      display: block; } }

@media screen and (max-width: 768px) {
  .pc {
    display: none; } }

@media screen and (max-width: 1100px) {
  .pc-l {
    display: none; } }

.pc-l-only {
  display: none; }
  @media screen and (max-width: 1100px) {
    .pc-l-only {
      display: block; } }
  @media screen and (max-width: 768px) {
    .pc-l-only {
      display: none; } }

.sp-s {
  display: none; }
  @media (max-width: 480px) {
    .sp-s {
      display: block; } }
